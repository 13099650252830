import styled from 'styled-components';

import Box from './box';

/**
 * @deprecated
 */
const Flex = styled(Box)`
  display: flex;
`;

export default Flex;
