import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  color,
  layout,
  typography,
  position,
  border,
} from 'styled-system';
import css from '@styled-system/css';

import Box from 'legacy/components/box';

/**
 * @deprecated
 */
const Section = styled('section')(
  css({
    width: '100%',
  }),
  variant({
    variants: {
      extraSmall: {
        py: [4, null, null, 5],
      },
      small: {
        py: [5, null, null, 6],
      },
      medium: {
        py: [6, null, null, 7],
      },
      large: {
        py: [7, null, null, 8],
      },
    },
  }),
  compose(space, color, layout, typography, position, border)
);

const Inner = styled(Box)(
  css({
    mx: ['1.5rem', null, 4, null, 'auto'],
    maxWidth: 'contentWidth',
  })
);

Section.Inner = Inner;

Section.defaultProps = {
  variant: 'medium',
};

export default Section;
