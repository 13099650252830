import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant, compose, space, typography, color, layout } from 'styled-system';
import propTypes from '@styled-system/prop-types';

/**
 * @deprecated
 */
const Heading = styled('h2')(
  variant({
    variants: {
      xl: {
        fontSize: 9,
        lineHeight: 1.2,
      },
      h1: {
        fontSize: 8,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: 7,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: 6,
        lineHeight: 1.25,
      },
      h4: {
        fontSize: 5,
        lineHeight: 1.25,
      },
      h5: {
        fontSize: 4,
        lineHeight: 1.5,
      },
    },
  }),
  compose(space, layout, color, typography)
);

Heading.defaultProps = {
  p: 0,
  mt: 0,
  fontFamily: 'heading',
  fontWeight: 'heading',
  color: 'blue',
  variant: 'h2',
};

Heading.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.color,
  ...propTypes.typography,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Heading;
