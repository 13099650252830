import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant, compose, space, color, layout, flexbox } from 'styled-system';
import css from '@styled-system/css';
import propTypes from '@styled-system/prop-types';

const borderWidth = '3px';

/**
 * @deprecated
 */
const Button = styled('button')(
  css({
    display: 'inline-block',
    borderWidth,
    borderStyle: 'solid',
    borderRadius: 'default',
    appearance: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: 'body',
    fontWeight: 'button',
    lineHeight: 1,
    letterSpacing: '0.04em',
    textDecoration: 'none',
    transition:
      'background 250ms ease-in, border-color 250ms ease-in-out, transform 150ms ease, box-shadow 300ms ease',
    '&:focus': {
      outline: 0,
      boxShadow: 'buttonFocus',
    },
  }),
  variant({
    variants: {
      primary: {
        bg: 'blue',
        borderColor: 'blue',
        color: 'white',
        '&:hover': {
          bg: 'blue20',
          borderColor: 'blue20',
        },
        '&:disabled': {
          bg: 'gray50',
          color: 'gray100',
        },
      },
      secondary: {
        bg: 'teal40',
        borderColor: 'teal40',
        color: 'white',
        '&:hover': {
          bg: 'teal30',
          borderColor: 'teal30',
        },
        '&:disabled': {
          bg: 'gray50',
          color: 'gray100',
        },
      },
      outline: {
        borderColor: 'blue',
        bg: 'white',
        color: 'blue',
        '&:hover': {
          bg: 'gray100',
        },
        '&:disabled': {
          bg: 'gray50',
          color: 'gray100',
        },
      },
      signup: {
        borderStyle: 'solid',
        borderColor: 'gray80',
        bg: 'white',
        color: 'blue',
        '&:hover': {
          bg: 'gray100',
        },
      },
      signupSelected: {
        borderStyle: 'solid',
        borderColor: 'teal',
        bg: 'teal90',
        color: '#0C706A', // Designers didn't give us a darker teal :shrug:
      },
      secondaryGradient: {
        // TODO: talk to design about using theme values here
        background: 'linear-gradient(84.37deg, #0F857E -0.04%, #19CFC4 99.96%)',
        border: 'none',
        borderRadius: 'large',
        fontSize: 3,
        color: 'white',
      },
    },
  }),
  variant({
    prop: 'btnSize',
    variants: {
      xxl: {
        px: [4, null, 5],
        py: ['1.5rem', null, 4, 5],
        fontSize: 3,
      },
      xl: {
        px: `calc(2rem - ${borderWidth})`,
        py: `calc(1.5rem - ${borderWidth})`,
        fontSize: 4,
      },
      lg: {
        px: `calc(1.5rem - ${borderWidth})`,
        py: `calc(1rem - ${borderWidth})`,
        fontSize: 3,
      },
      md: {
        // 40px
        px: `calc(1rem - ${borderWidth})`,
        py: `calc(12px - ${borderWidth})`,
        fontSize: 2,
      },
      sm: {
        px: `calc(1rem - ${borderWidth})`,
        py: `calc(9px - ${borderWidth})`,
        fontSize: 1,
      },
    },
  }),
  compose(space, layout, color, flexbox)
);

// const Button = forwardRef((props, ref) => <StyledButton ref={ref} {...props} />);

const btnSizes = PropTypes.oneOf(['sm', 'md', 'lg', 'xl']);

Button.propTypes = {
  /**
   * Controls the margin and padding of the Button
   */
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.buttonStyle,
  /**
   * Preset color variants for the Button
   */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'signup',
    'signupSelected',
    'outline',
    'secondaryGradient',
  ]),
  /**
   * Preset size variants for the Button
   */
  btnSize: PropTypes.oneOfType([btnSizes, PropTypes.arrayOf(btnSizes)]),
  /**
   * The inner content of the Button
   */
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  variant: 'primary',
  btnSize: 'md',
};

export default Button;
