import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography, color, space, layout, border, compose } from 'styled-system';
import css from '@styled-system/css';
import propTypes from '@styled-system/prop-types';
import NextLink from 'next/link';

import Box from './box';

const StyledLink = styled(Box).attrs({ as: 'a' })`
  ${css({
    position: 'relative',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'link',
  })};

  ${compose(typography, color, space, layout, border)};
`;

/**
 * @deprecated
 */
const Link = forwardRef(({ href, external, children, ...props }, ref) =>
  external ? (
    <StyledLink
      href={href}
      ref={ref}
      as="a"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </StyledLink>
  ) : (
    <NextLink passHref href={href} legacyBehavior>
      <StyledLink ref={ref} as="a" {...props}>
        {children}
      </StyledLink>
    </NextLink>
  )
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
};

Link.defaultProps = {
  external: false,
};

export default Link;
