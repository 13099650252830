import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, space, layout, color, typography, shadow } from 'styled-system';
import css from '@styled-system/css';

import Box from './box';

const StyledCard = styled(Box)(
  css({
    borderRadius: 'large',
    bg: 'gray100',
  }),
  compose(space, layout, color, typography, shadow)
);

const Inner = styled(Box)(
  css({
    px: ['1.5rem', null, 4],
    py: [4, null, 5],
  }),
  compose(space, layout, color, typography)
);

/**
 * @deprecated
 */
const Card = ({ children, ...props }) => <StyledCard {...props}>{children}</StyledCard>;

Card.Inner = Inner;

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Card;
