/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';

import rollbar from 'utils/rollbar';

// console statements are suppressed in prod
export const logger = {
  info: (message, ...args) => {
    console.log(message, { ...args });
    rollbar.info(message, ...args);
    datadogLogs.logger.info(message, { ...args });
  },
  warn: (message, err = {}, ...args) => {
    console.warn(message, err, { ...args });
    rollbar.warn(message, err, ...args);
    datadogLogs.logger.warn(message, { ...args }, err);
  },
  error: (message, err = {}, ...args) => {
    console.error(message, err, { ...args });
    rollbar.error(message, err, ...args);
    datadogLogs.logger.error(message, { ...args }, err);
  },
  critical: (message, err = {}, ...args) => {
    console.error(message, err, { ...args });
    rollbar.critical(message, err, ...args);
    // datadog does not have a critical level
    datadogLogs.logger.error(message, { ...args }, err);
  },
};
