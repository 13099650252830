import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  position,
  typography,
  flexbox,
  border,
} from 'styled-system';

/**
 * @deprecated
 */
const Box = styled.div`
  /* min-width: 0; */
  ${compose(space, color, layout, position, typography, flexbox, border)};
`;

export default Box;
