import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  layout,
  typography,
  color,
  TypographyProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
} from 'styled-system';

type Variant = 'eyebrow' | 'eyebrowMasthead' | 'large' | 'default' | 'small' | null;

type Props = {
  variant?: Variant | Variant[];
} & SpaceProps &
  ColorProps &
  LayoutProps &
  TypographyProps;

/**
 * @deprecated
 */
const Text = styled.p.attrs<Props>((props) => ({
  ...props,
  p: props.p ?? 0,
  fontFamily: props.fontFamily ?? 'body',
  color: props.color ?? 'text',
  variant: props.variant ?? 'default',
}))<Props>(
  variant({
    variants: {
      eyebrow: {
        mt: 0,
        mb: 3,
        fontSize: 4,
        fontWeight: 'light',
        textTransform: 'uppercase',
        letterSpacing: '0.08em',
      },
      eyebrowMasthead: {
        mt: 0,
        mb: 4,
        fontSize: 3,
        lineHeight: 'bodyLarge',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.08em',
      },
      large: {
        mt: 3,
        fontSize: 'bodyLarge',
        lineHeight: 'bodyLarge',
      },
      default: {
        mt: 3,
        fontSize: 'body',
        lineHeight: 'body',
      },
      small: {
        mt: 3,
        fontSize: 'small',
      },
    },
  }),
  compose(space, color, layout, typography)
);

export default Text;
