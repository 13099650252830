import NextHead from 'next/head';
import { ReactNode } from 'react';

type Props = {
  url: string;
  children?: ReactNode;
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
};

export const Head = ({
  url,
  children,
  title = process.env.NEXT_PUBLIC_SITE_TITLE as string,
  description = process.env.NEXT_PUBLIC_SITE_DESCRIPTION as string,
  image = 'https://images.ctfassets.net/tj9jxg7kaxby/5pfZ2fwcihpJt8VrjB8vAj/99f9d1541caed73f63c76d3df4af8a24/logo_hi.jpg',
  noIndex = false,
}: Props) => {
  const pageTitle = `${title} | Human Interest`;

  return (
    <NextHead>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {noIndex && <meta name="robots" content="noindex" />}
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={pageTitle} />
      <meta name="og:description" property="og:description" content={description} />
      <meta property="og:site_name" content="Human Interest" />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@humaninteresthq" />
      <meta name="twitter:creator" content="" />
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      <link rel="canonical" href={url} />
      <link rel="icon" href="/favicon.ico?v=2" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#022a4d" />
      <meta name="msapplication-TileColor" content="#022a4d" />
      <meta name="theme-color" content="#ffffff" />
      {children}
    </NextHead>
  );
};
